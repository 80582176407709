import Helpers from '@components/Helpers/Helpers';

/**
 * Controllers
 * @version 0.10.7
 */
const Controllers = {
  users: {
    get_login: (data = {}) => Helpers.axios({ url: '/users/login', method: 'POST', data }),
    get_user_password: (data = {}) => Helpers.axios({ url: '/users/password', method: 'POST', data }),
  },
}

const addModule = ({
  name,
  recursive = false,
  child = false,
  parent = '',
  removeGetId = false,
  removeInsertUpdate = false,
  removeGetPublic = false,
  hasFile = false,
  changeEnabled = false
}) => {
  let m = {}

  if (child) {
    m[`get_${name}`] = (data = {}) => Helpers.axios({ url: `/${name}/get/all/${data[`p_id_${parent}`]}` })
    if (!removeGetPublic) {
      m[`get_${name}_public`] = (data = {}) => Helpers.axios({ url: `/${name}/get/public/${data[`p_id_${parent}`]}` })
    }
  } else {
    m[`get_${name}`] = () => Helpers.axios({ url: `/${name}/get/all` })
    if (!removeGetPublic) {
      m[`get_${name}_public`] = () => Helpers.axios({ url: `/${name}/get/public` })
    }
  }

  if (!removeGetId) {
    m[`get_${name}_id`] = (data = {}) => Helpers.axios({ url: `/${name}/get/id/${data[`p_id_${name}`]}` })
  }

  if (!removeInsertUpdate) {
    m[`${name}_insert_update`] = (data) => Helpers.axios({ url: `/${name}/insert/update`, method: 'POST', data, hasFile })
  }

  if (changeEnabled) {
    m[`${name}_change_enabled`] = (data) => Helpers.axios({ url: `/${name}/change/enabled`, method: 'POST', data })
  }

  m[`${name}_delete`] = (data) => Helpers.axios({ url: `/${name}/delete`, method: 'POST', data })

  if (recursive) {
    m[`get_${name}_parent_childs`] = () => Helpers.axios({ url: `/${name}/get/parent/childs` })
    m[`${name}_child_delete`] = (data) => Helpers.axios({ url: `/${name}/child/delete`, method: 'POST', data })
  }

  return m
}

const modules = [
  { name: 'tags_items' },
  { name: 'worker', hasFile: true },
  { name: 'profile' },
  { name: 'category' },
  { name: 'product', hasFile: true },
  { name: 'labels' },
  { name: 'type_customers' },
  { name: 'customers', hasFile: true },
  { name: 'unit_measure' },
  { name: 'expenses' },
  { name: 'document', changeEnabled: true },
  { name: 'document_det', removeGetId: true, removeInsertUpdate: true, removeGetPublic: true, child: true, parent: 'document' },
  { name: 'country' },
  { name: 'format_pay' },
  { name: 'method_pay' },
  { name: 'cdfi' },
  { name: 'warehouse' },
  { name: 'inventory' },
  { name: 'product_history' },
  { name: 'product_history_m' },
  { name: 'sale_detail' },
  { name: 'transfers' },
]

modules.forEach(m => {
  Controllers[m.name] = addModule(m)
})

Controllers.profile.get_profile_tags_items = ({ p_id_profile = '' } = {}) => Helpers.axios({ url: `/profile/get/tagsitems/${p_id_profile}` })

Controllers.product.product_insert_update_mass = data => Helpers.axios({ url: `/product/insert/update/mass`, method: 'POST', data, hasFile: true })

//Copio del original
Controllers.product.updateExcelInventory = data => Helpers.axios({ url: `/products_history/excel_inventory`, method: 'POST', data, hasFile: true })
Controllers.product.massPrices = data => Helpers.axios({ url: `/products/mass_prices`, method: 'POST', data, hasFile: true })

//Traspasos masivos
Controllers.product_history.moves = data => Helpers.axios({ url: `/products_history_m/moves`, method: 'POST', data, hasFile: true })

// Product History M
Controllers.product_history_m.updateExcelInventory = data => Helpers.axios({ url: `/products_history_m/excel_inventory`, method: 'POST', data, hasFile: true })
Controllers.product_history_m.moves = data => Helpers.axios({ url: `/products_history_m/moves`, method: 'POST', data, hasFile: true })

//Nuevo traspaso
Controllers.transfers.create = data => Helpers.axios({ url: `/transfers/create`, method: 'POST', data, hasFile: true })
Controllers.transfers.apply = data => Helpers.axios({ url: `/transfers/apply`, method: 'POST', data })
Controllers.transfers.cancel = data => Helpers.axios({ url: `/transfers/cancel`, method: 'POST', data })

//Carga de entradas y salidas
Controllers.product_history.moves = data => Helpers.axios({ url: `/products_history/moves`, method: 'POST', data, hasFile: true })

Controllers.dashboard = {
  get_sale_daily: _ => Helpers.axios({ url: '/dashboard/sale/daily' }),
  get_sale_total: ({ p_date_start, p_date_end }) => Helpers.axios({ url: `/dashboard/sale/total/${p_date_start}/${p_date_end}` }),
  get_sale_worker: ({ p_date_start, p_date_end }) => Helpers.axios({ url: `/dashboard/sale/worker/${p_date_start}/${p_date_end}` }),
  get_sale_product: ({ p_date_start, p_date_end }) => Helpers.axios({ url: `/dashboard/sale/product/${p_date_start}/${p_date_end}` }),
  get_sale_average: ({ p_date_start, p_date_end }) => Helpers.axios({ url: `/dashboard/sale/average/${p_date_start}/${p_date_end}` }),
}

Controllers.dashboardGlobal = {
  get_sale_average: ({ p_date_start, p_date_end }) => Helpers.axios({ url: `/dashboard_global/sale/average/${p_date_start}/${p_date_end}` }),
  get_sale_total: ({ p_date_start, p_date_end }) => Helpers.axios({ url: `/dashboard_global/sale/total/${p_date_start}/${p_date_end}` }),
  get_sale_daily: _ => Helpers.axios({ url: '/dashboard_global/sale/daily' }),
  get_sale_worker: ({ p_date_start, p_date_end }) => Helpers.axios({ url: `/dashboard_global/sale/worker/${p_date_start}/${p_date_end}` }),
  get_sale_product: ({ p_date_start, p_date_end }) => Helpers.axios({ url: `/dashboard_global/sale/product/${p_date_start}/${p_date_end}` }),
}

Controllers.worker.current = data => Helpers.axios({ url: `/worker/current` })
//
// Reboot Project
//
// Controllers.warehouse.all = (p_id_warehouse) => Helpers.axios({ url: `/warehouses/get` })

export default Controllers;